const reducerTypes = {

  // Auth types
  login: '[Auth] Login',
  logout: '[Auth] Logout',
  authCheckingFinish: '[Auth] Finish Checking Login State',

  // UI types
  uiSetError: '[UI] Set Error',
  uiRemoveError: '[UI] Remove Error',
  uiStartLoading: '[UI] Start Loading',
  uiFinishLoading: '[UI] Finish Loading',
  uiStripePromise: '[UI] Stripe Promise',

}

export { reducerTypes as default }