import React from "react";
import { AiOutlineLoading } from "react-icons/ai";

// import { ReactComponent as TgoatLogo } from "../../../assets/tshare-logo.svg";

const LoadingSuspenseFallback = () => {
  return (
    <main className='h-screen flex justify-center items-center'>
      <section className='flex flex-col justify-center items-center space-y-1 px-2'>
        {/* <TgoatLogo className="w-24 h-10 lg:w-32 lg:h-14" /> */}
        <p className="text-gray-500 font-open-sans lg:text-lg text-center">
          Your Networks have untapped economic potential.
        </p>
        <AiOutlineLoading className='w-6 h-6 animate-spin text-gray-500' />
      </section>
    </main>
  );
};

export default LoadingSuspenseFallback;