import Axios from "axios";
import links from "../../types/links.types";

const baseURL = process.env.REACT_APP_API_URL;

export const handleAuth = async () => {
    localStorage.setItem("tgoat-admin-status", "[fetching]")
    Axios({
        method: "GET",
        withCredentials: true,
        url: `${baseURL}/auth/check`,
      }).then((res) => {
        if(res.data.message === "[no-login]" && window.location.pathname === "/login") {
          localStorage.setItem("tgoat-admin-status", "[no-login]")
        }
        
        if(res.data.message === "[logged-in]" && window.location.pathname === "/login") {
          localStorage.setItem("tgoat-admin-status", "[logged-in]")
          window.location.href = links.home;
        }
        
        if(res.data.message === "[logged-in]" && window.location.pathname !== "/login") {
          localStorage.setItem("tgoat-admin-status", "[logged-in]")
        }
        
        if(res.data.message === "[no-login]" && window.location.pathname !== "/login") {
          localStorage.setItem("tgoat-admin-status", "[no-login]")
          window.location.href = links.login;
      }
    }).catch((error) => {
      if(error.message === "Network Error" && window.location.pathname !== "/login") {
        localStorage.setItem("tgoat-admin-status", "[network-error]")
        window.location.href = links.login;
      }
    });
};