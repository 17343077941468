import React from 'react';
import { Provider } from 'react-redux';
import { HelmetProvider } from "react-helmet-async";
import { store } from './store/store';
import { App } from './App';

export const ProviderApp = () => {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Provider>
  )
}