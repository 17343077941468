import reducerTypes from '../types/reducerTypes';

/**
 * This reducer changes in the store things about authentication.
 *
 * Example: {
 *  uid: 'sadfgrwoie13i643oiejdisca',
 *  name: 'John Doe',
 *  email: 'john@doe.com'
 * }
 *
 * @param {object} state current state
 * @param {object} action action to be performed
 * @returns state
 */

type actionType = {
  type: string,
  payload: any
};

const authReducer = (state = {}, action: actionType) => {
  switch (action.type) {
    case reducerTypes.login:
      return {
        checking: false,
        uid: action.payload.uid,
        name: action.payload.name,
        username: action.payload.username,
        email: action.payload.email,
        profilePhoto: action.payload.profilePhoto,
        userStatus: action.payload.userStatus,
        companyName: action.payload.companyName,
        hasNotifications: action.payload.hasNotifications,
      };
    case reducerTypes.authCheckingFinish:
      return {
        ...state,
        checking: false,
      }
    case reducerTypes.logout:
      return {
        checking: false,
      };
    default:
      return state;
  }
};

export { authReducer as default };
