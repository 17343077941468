import reducerTypes from '../types/reducerTypes';

const initialState = {
  loading: false,
  msgError: null,
};

type actionType = {
  type: string,
  payload: any
};

const uiReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case reducerTypes.uiSetError:
      return {
        ...state,
        msgError: action.payload,
      };
    case reducerTypes.uiRemoveError:
      return {
        ...state,
        msgError: null,
      };
    case reducerTypes.uiStartLoading:
      return {
        ...state,
        loading: true,
      };
    case reducerTypes.uiFinishLoading:
      return {
        ...state,
        loading: false,
      };
    case reducerTypes.uiStripePromise:
      return {
        ...state,
        stripePromise: action.payload.stripePromise
      }
    default:
      return state;
  }
};

export { uiReducer as default };
