import React, { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import links from "../types/links.types";
import { handleAuth } from "../helpers/auth/handleAuth.helper";

//* Routers

//* Screens
import LoadingSuspenseFallback from "../components/ui/loading/LoadingSuspenseFallback.component";
// const HomeScreen = lazy(() => import("../screens/general/Home.screen"));
// const ShareablesDashboard = lazy(() => import("../screens/dashboard/ShareablesDashboard.screen"));
// const UsersDashboard = lazy(() => import("../screens/dashboard/UsersDashboard.screen"));
const LoginScreen = lazy(() => import("../screens/general/Login.screen"));
const Generate = lazy(() => import("../screens/generate/Generate.screen"));
const NotFound = lazy(() => import("../screens/general/404.screen"));

export const AppRouter = () => {

  useEffect(() => {
    async function fetchData() {
      await handleAuth();
    }
    fetchData();
  },[])

  if(localStorage.getItem("tgoat-admin-status") === null || localStorage.getItem("tgoat-admin-status") === undefined || localStorage.getItem("tgoat-admin-status") === "[fetching]" || localStorage.getItem("tgoat-admin-status") === "[network-error]") {
    return( <LoadingSuspenseFallback/> )
  }

  return (
    <Suspense fallback={<LoadingSuspenseFallback/>}>
      <Router>
        <Switch>

          {/* <Route
            exact
            path={links.home}
            component={HomeScreen}
          /> */}
          
          <Route
            exact
            path={links.home}
            component={Generate}
          />
          
          <Route
            exact
            path={links.root}
            component={Generate}
          />
          
          {/* <Route
            exact
            path={links.root}
            component={HomeScreen}
          /> */}
          
          <Route
            exact
            path={links.login}
            component={LoginScreen}
          />
          
          {/* <Route
            exact
            path={links.shareables_dashboard}
            component={ShareablesDashboard}
          />

          <Route
            exact
            path={links.users_dashboard}
            component={UsersDashboard}
          /> */}
          
          <Route
            exact
            path={links.generate}
            component={Generate}
          />
          
          <Route
            exact
            path={"*"}
            component={NotFound }
          />

        </Switch>
      </Router>
    </Suspense>
  )
}
